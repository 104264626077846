body {
	margin: 0;
	padding: 0;
	font-family: "Open Sans",sans-serif;
}
.main-404{
	width: 100%;
	height: 100vh;
	background: url("../../img/background-404.jpg") no-repeat center;
	background-size: cover;
	display: table;   
}
.oops-box {
	display: table-cell;
	vertical-align: middle;
	text-align: center;
	padding: 50px 0;
	h3 { 
		font-size: 150px;
		line-height: 159px;
		color: rgb(0,180,236);
		font-weight: 900;
		margin: 0;
	}
	img {
		max-width: 100%;
	}
	p {
		font-size: 26px;
		line-height: 39px;
		color: rgb(24,77,160);
		font-weight: bold;
		margin: 0; 
		text-transform: uppercase;
		font-weight: bold;		
    	margin-bottom: 35px;
	}
	a {
		font-size: 20px;
		line-height: 30px;
		color: rgb(255,255,255);
		text-transform: uppercase;
		font-weight: bold;
		width: 240px;
		height: 52px;
		border-radius: 6px;
		background-color: #004ba3;
		border: none;
		text-decoration: none;
		padding: 10px 30px;
	}
}
@media (max-width: 767px) {
	.oops-box {
		h3 {
			font-size: 70px;
			line-height: 70px;
		}
		p {
			font-size: 22px;
    		line-height: 29px;
    		margin-bottom: 20px;
		}
		a {			
			font-size: 15px;
		}
	}
}
@media (max-width: 320px) {
	.oops-box {
		padding: 0;
	}
}